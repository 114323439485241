// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-home-index-js": () => import("../src/templates/Home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-gallery-index-js": () => import("../src/templates/Gallery/index.js" /* webpackChunkName: "component---src-templates-gallery-index-js" */),
  "component---src-templates-project-detail-index-js": () => import("../src/templates/ProjectDetail/index.js" /* webpackChunkName: "component---src-templates-project-detail-index-js" */),
  "component---src-templates-services-index-js": () => import("../src/templates/Services/index.js" /* webpackChunkName: "component---src-templates-services-index-js" */),
  "component---src-templates-talents-index-js": () => import("../src/templates/Talents/index.js" /* webpackChunkName: "component---src-templates-talents-index-js" */),
  "component---src-templates-talent-detail-index-js": () => import("../src/templates/TalentDetail/index.js" /* webpackChunkName: "component---src-templates-talent-detail-index-js" */),
  "component---src-templates-about-index-js": () => import("../src/templates/About/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-imprint-index-js": () => import("../src/templates/Imprint/index.js" /* webpackChunkName: "component---src-templates-imprint-index-js" */),
  "component---src-templates-data-protection-index-js": () => import("../src/templates/DataProtection/index.js" /* webpackChunkName: "component---src-templates-data-protection-index-js" */),
  "component---src-templates-404-index-js": () => import("../src/templates/404/index.js" /* webpackChunkName: "component---src-templates-404-index-js" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

