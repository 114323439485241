/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { GatsbyBrowser } from 'gatsby';
import React, { useCallback } from 'react';
import '../styles/reset.scss';
import '../index.css';
import '../App.scss';
import { isIOS, isChrome } from 'react-device-detect';
import measureScrollbar from 'measure-scrollbar';

function setVw() {
  let scrollbarWidth = measureScrollbar();

  document.documentElement.style.setProperty(
    '--scrollbarWidth',
    `${scrollbarWidth}px`
  );
}

function hasSomeParentTheClass(element: any, classname: string): any {
  if (element.classList && element.classList.contains(classname)) return true;
  return (
    element.parentNode && hasSomeParentTheClass(element.parentNode, classname)
  );
}

function preventPullToRefresh(event: any) {
  const target = event.target;
  const scrollableClass = 'scrollable';

  if (hasSomeParentTheClass(target, scrollableClass)) {
    return true;
  }

  return event.preventDefault();
}

const WrappedRootElement: React.FC = ({ children }) => {
  const rootElementRef = useCallback(rootElement => {
    if (rootElement && isIOS && isChrome) {
      rootElement.addEventListener('touchforcechange', preventPullToRefresh);
      rootElement.addEventListener('touchmove', preventPullToRefresh);
    }

    setVw();
    typeof window !== 'undefined' && window.addEventListener('resize', setVw);

    //hardcore danger zone
  }, []);

  return (
    <div ref={rootElementRef} className={'App'}>
      <div className={'App__pageContent'}>{children}</div>
    </div>
  );
};

export const gatsbyBrowser: GatsbyBrowser = {
  wrapRootElement: ({ element }) => {
    return <WrappedRootElement>{element}</WrappedRootElement>;
  },
};
